<script setup lang="ts">
import { computed } from 'vue'
import DaysTape from './days-tape/datepicker-days-tape.vue'
import DefaultPicker from './default/datepicker-default.vue'
import MonthText from './month-text/datepicker-month-text.vue'
import TimePicker from './time/datepicker-time.vue'
import Timezone from './timezone/datepicker-timezone.vue'

const props = withDefaults(
  defineProps<{
    type?: 'default' | 'month-text' | 'days-tape' | 'timezone' | 'time'
  }>(),
  {
    type: 'default',
  }
)

const component = computed(() => {
  switch (props.type) {
    case 'month-text':
      return MonthText
    case 'days-tape':
      return DaysTape
    case 'timezone':
      return Timezone
    case 'time':
      return TimePicker
    case 'default':
    default:
      return DefaultPicker
  }
})
</script>

<template>
  <component :is="component" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, slot) in $slots" #[slot]>
      <slot :name="slot"></slot>
    </template>
  </component>
</template>
