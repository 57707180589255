<script setup lang="ts">
import { ValidationProvider } from 'vee-validate'
import { ref, computed, watch, nextTick } from 'vue'
import DatePicker from 'vue2-datepicker'
import { DateType } from '/~/types/date'
import ui from '/~/core/ui'
import { createDate, formatDate } from '/~/utils/format/date'
import BaseField from '/~/components/base/field/base-field.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useInput } from '/~/composables/base/use-input'
import { useLocalization } from '/~/composables/localization'
import './datepicker-time.css'

const props = withDefaults(
  defineProps<{
    value?: Date | string
    valueType?: string
    placeholder?: string
    format?: string
    clearable?: boolean
    appendToBody?: boolean
    autofocus?: boolean
    step?: number
    iconPosition?: 'left' | 'right'
    editable?: boolean
    entryClass?: string
    start?: string
    end?: string
    popupStyle?: object
    label?: string
    required?: boolean
    nolabel?: boolean
    error?: string
    floated?: boolean
    disabled?: boolean
    name?: string
    validation?: object
  }>(),
  {
    value: '',
    valueType: 'format',
    placeholder: 'Enter Time',
    format: 'time24',
    clearable: false,
    appendToBody: true,
    autofocus: false,
    step: 5,
    iconPosition: 'left',
    editable: false,
    entryClass: 'overflow-hidden rounded-lg h-10',
    start: '00:00',
    end: '23:45',
    popupStyle: () => ({}),
  }
)

const emit = defineEmits<{
  (e: 'input', value: Date | string): void
}>()

const localization = useLocalization()

const { validationProviderRef, isFocused, id, onInput } = useInput(props, emit)

const time = ref(props.value)
const timepicker = ref(null)

const formattedStep = computed(() => ({
  mobile: props.step * 60,
  desktop: `00:${props.step}`,
}))

watch(
  () => props.value,
  (val) => {
    validationProviderRef.value?.validate(val)
    time.value = val
  }
)

const panelOpen = () => {
  isFocused.value = true

  if (props.autofocus && !props.value) {
    nextTick(() => focusAnchorTime())
  }
}

const focusAnchorTime = () => {
  // package does not support auto focus and scrolling to current time
  const list = document.querySelector('.mx-time-content .mx-scrollbar-wrap')

  if (list === null) return

  const options = list.querySelectorAll('.mx-time-option')
  const anchorTime = formatDate(
    props.format as DateType,
    createDate().startOf('hour').add(30, 'minute')
  )

  for (const opt of options) {
    if (opt.classList.contains('active')) opt.classList.remove('active')

    if (opt.innerText.includes(anchorTime)) {
      nextTick(() => {
        list.scrollTop = opt.offsetTop - 80
      })
    }
  }
}
</script>

<template>
  <validation-provider
    v-slot="{ errors }"
    v-bind="validation"
    ref="validationProviderRef"
    :mode="ui.mobile ? validation.mode : 'aggressive'"
    slim
  >
    <base-field
      :label="label"
      :required="required"
      :focused="isFocused"
      :expanded="isFocused || !!value || !!placeholder"
      :nolabel="nolabel"
      :error="errors[0] || error"
      class="timepicker m-0"
      :class="{
        'icon-left': iconPosition === 'left',
        'icon-right': iconPosition === 'right',
      }"
      :floated="floated"
      :entry-class="entryClass"
    >
      <input
        v-if="ui.mobile"
        v-model="time"
        class="absolute inset-0 z-10 h-full w-full opacity-0"
        type="time"
        :step="formattedStep.mobile"
        @change="$emit('input', time)"
      />
      <date-picker
        ref="timepicker"
        v-model="time"
        :placeholder="placeholder"
        :clearable="clearable"
        :append-to-body="appendToBody"
        :value-type="valueType"
        :name="`${name}-timepicker`"
        :time-picker-options="{
          start: start,
          step: formattedStep.desktop,
          end: end,
        }"
        :format="localization.getDatePickerFormat(format)"
        :editable="editable"
        type="time"
        lang="en"
        :popup-class="id"
        :popup-style="popupStyle"
        :disabled="disabled"
        @open="panelOpen"
        @input="onInput"
        @change="isFocused = false"
        @blur="isFocused = false"
        @close="isFocused = false"
        @focus="isFocused = true"
      >
        <template #icon-calendar>
          <div class="w-6">
            <base-icon
              svg="plain/clock-filled"
              size="md"
              class="max-w-5 bg-default"
            />
          </div>
        </template>
      </date-picker>
    </base-field>
  </validation-provider>
</template>
